<template>
  <header class="absolute inset-x-0 top-0 z-50">
    <nav class="flex items-center justify-between py-6 px-8 sm:px-12" aria-label="Global">
      <div class="flex lg:flex-1">
        <a v-if="showDesktop" href="/" class="-m-1.5 p-1.5">
          <span class="sr-only">Home</span>
          <img class="h-16 w-auto" src="/PO_logo.png" alt="" />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-900" @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <i class="fa-solid fa-bars h-6 w-6" aria-hidden="true"></i>
        </button>
      </div>
      <div v-if="showDesktop" class="hidden lg:flex lg:gap-x-20 text-deep-blue">
        <a v-for="item in navigation" :key="item.name" :href="item.href" class="hover:text-orange text-base font-semibold leading-6">{{ item.name }}</a>
        <slot name="logout"></slot>
      </div>
      <!-- <div class="hidden lg:flex lg:flex-1 lg:justify-end text-deep-blue">
      </div> -->
    </nav>
    <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-50" />
      <DialogPanel class="fixed top-0 right-0 z-50 w-1/2 overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-start justify-between">
          <div class="flow-root">
            <div class="divide-y divide-gray-500/10">
              <div class="space-y-4 pb-2">
                <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
              </div>
              <div class="py-4 space-y-4">
                <slot name="logout"></slot>
              </div>
            </div>
          </div>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <i class="fa-solid fa-xmark h-6 w-6" aria-hidden="true"></i>
          </button>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel } from '@headlessui/vue'

defineProps({
  admin: Boolean,
  showDesktop: Boolean
})

const navigation = [
  { name: 'Events', href: '/events', nestedLinks: [
    { name: 'Arrival Drinks', href: '/events' },
    { name: 'The Wedding', href: '/events' },
    { name: 'Recovery Swim', href: '/events' },
  ] },
  // { name: 'RSVP', href: '#' },
  { name: 'Travel', href: '/travel', nestedLinks: [
    { name: 'Train to Annecy', href: '#' },
    { name: 'Fly to Geneva', href: '#' },
    { name: 'Local Buses', href: '#' },
    { name: 'Taxis', href: '#' },
  ] },
  { name: 'Where To Stay', href: '/where_to_stay', nestedLinks: [
    { name: 'Hotels', href: '#' },
    { name: "Chambres d'hotes", href: '#' },
    { name: 'Airbnb / Villa Rentals', href: '#' },
  ] },
  // { name: 'Extending Your Trip', href: '#' },
  // { name: 'Gift', href: '#' },
  // { name: 'FAQ', href: '#' }
]
const mobileMenuOpen = ref(false)
</script>
