<template>
  <div ref="mapElement" style="height: 800px; width: 100%;"></div>
  <div v-if="admin && invalidPlaces.length > 0" class="text-red-500">
    <p>The following places could not be found: Please check/update the place ID</p>
    <ul>
      <li v-for="place in invalidPlaces">{{ place }}</li>
    </ul>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from 'vue'
import { Loader } from '@googlemaps/js-api-loader';

const props = defineProps({
  center: {
    type: Object,
    default: () => ({ lat: 0, lng: 0 })
  },
  zoom: {
    type: Number,
    default: 8
  },
  places: {
    type: Array,
    default: () => []
  },
  apiKey: {
    type: String,
    default: ''
  },
  admin: {
    type: Boolean,
    default: false
  }
})

const mapElement = ref(null)
const map = ref(null)
const markers = ref([])
const invalidPlaces = ref([])

onMounted(async () => {
  await initMap();
})

onUnmounted(() => {
  clearMarkers();
})

const clearMarkers = () => {
  markers.value.forEach(marker => marker.setMap(null))
  markers.value = []
}

const initMap = async () => {
  const loader = new Loader({
    apiKey: props.apiKey,
    version: 'weekly',
    libraries: ['places']
  })
  const { Map } = await loader.importLibrary("maps");
  const { Place } = await loader.importLibrary("places");

  map.value = new Map(mapElement.value, {
    center: props.center,
    zoom: props.zoom,
    styles: mapStyles.value
  })
  for (const place of props.places) {
    if (!place.map_id) {
      invalidPlaces.value.push(place.name)
      continue
    }
    if ((place.updated_at < (new Date() - 1000 * 60 * 60 * 24 * 7)) || Object.keys(place.api_details).length === 0) {
      try {
        const place_details = new Place({ id: place.map_id })
        await place_details.fetchFields({ fields: ['displayName', 'location', 'formattedAddress', 'websiteURI', 'rating'] })
        savePlaceDetails(place, place_details)
        addMarker(place, place.api_details)
      } catch (error) {
        invalidPlaces.value.push(place.name)
      }
    } else {
      addMarker(place, place.api_details)
    }
  }
}
const addMarker = (place, place_details) => {
  const marker = new google.maps.Marker({
    map: map.value,
    position: place_details.location,
    title: place_details.displayName,
    icon: {
      url: place.place_type === 'Event' ? "venue_pin.png" : "house_pin.png",
      scaledSize: new google.maps.Size(24, 34)
    }
  })
  const infowindow = new google.maps.InfoWindow({
    ariaLabel: place_details.displayName,
    headerContent: place_details.displayName,
    content: infoContent(place, place_details)
  })
  marker.addListener('click', () => {
    infowindow.open(map.value, marker)
  })
  markers.value.push(marker)
}

const infoContent = (place, place_details) => {
  return `
    <div class="flex flex-col gap-1 pt-2 sm:pt-0 text-xs md:text-sm">
      <p class="flex flex-col sm:flex-row gap-2">`
    + (place.price_range ? `<span class="font-semibold">Price Range: ${getPriceRange(place)}</span>` : '')
    + (place.place_type !== 'Event' ? `<span class="font-semibold">Rating: ${place_details.rating}</span>` : '')
    + `</p><a class="text-blue-500 hover:underline" href="${place.website_url || place_details.websiteURI}" target="_blank">Visit Website</a>
      <p>${place_details.formattedAddress}</p>
  ` + (place.notes ? `<p>${place.notes}</p>` : '') + `</div>`
}

const getPriceRange = (place) => {
  return '€'.repeat(place.price_range || 1);
}

const savePlaceDetails = (place, place_details) => {
  fetch(`/places/${place.id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      "X-CSRF-Token": documentCsrfToken()
    },
    body: JSON.stringify({ place: { id: place.id, api_details: place_details } })
  }).then((data) => { console.log('saved place details for ', place.name, data) });
}

const documentCsrfToken = () => {
  return document
    .querySelector("meta[name='csrf-token']")?.getAttribute("content");
}

const mapStyles = ref([
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [{ color: '#c5c4a9' }]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#000000' }]
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#000000' }]
  },
  {
    featureType: 'landscape.natural.landcover',
    elementType: 'geometry.fill',
    stylers: [{ color: '#94a687' }]
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'geometry.fill',
    stylers: [{ color: '#54624a' }]
  },
  {
    featureType: 'poi',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [{ color: '#aaaaaa' }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#606060' }]
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#aaaaaa' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#606060' }]
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.fill',
    stylers: [{ color: '#d6d6d6' }]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#606060' }]
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [{ color: '#6190ac' }]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#ffffff' }]
  },
  {
    featureType: 'landscape',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  }
])

// Watch for changes in center or zoom
watch(() => props.center, (newCenter) => {
  if (map.value) {
    map.value.setCenter(newCenter)
  }
}, { deep: true })

watch(() => props.zoom, (newZoom) => {
  if (map.value) {
    map.value.setZoom(newZoom)
  }
})

</script>
